import { Link } from "~components";
import React from "react";
import Footer from "./style";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const textosCentroAyuda = [
  "¿Cómo puedo restablecer mi contraseña?",
  "¿Cuál es la política de devolución de productos?",
  "¿Cómo puedo actualizar la información de mi perfil?",
  "¿Qué métodos de pago aceptan?",
  "¿Cómo puedo contactar al servicio de atención al cliente?",
  "¿Dónde puedo encontrar información sobre los precios y planes disponibles?",
  "¿Cuál es el tiempo de entrega estimado para los pedidos?",
  "¿Cómo puedo cancelar mi suscripción?",
  "¿Qué debo hacer si no puedo iniciar sesión en mi cuenta?",
  "¿Cuáles son los requisitos del sistema para utilizar la aplicación?",
  "¿Cómo puedo obtener un reembolso por un artículo defectuoso?",
  "¿Hay alguna guía de inicio rápido disponible?",
  "¿Dónde puedo encontrar tutoriales para usar las funciones avanzadas del producto?",
  "¿Qué debo hacer si no encuentro la respuesta a mi pregunta en el centro de ayuda?",
  "¿Cómo puedo reportar un problema técnico?",
  "¿Qué medidas de seguridad tienen implementadas para proteger mi información personal?",
  "¿Cuál es el proceso para solicitar una garantía o reparación?",
  "¿Puedo transferir mi cuenta a otro dispositivo?",
  "¿Dónde puedo encontrar los términos y condiciones de uso del servicio?",
  "¿Hay alguna forma de obtener soporte técnico en tiempo real?",
];

export default function FooterSix() {
  const [texto, setTexto] = React.useState("");
  const handleChange = (event) => {
    setTexto(event.target.value);
  };

  // Filtrar los textos del centro de ayuda según el texto ingresado
  const textosFiltrados = textosCentroAyuda.filter((textoAyuda) =>
    textoAyuda.toLowerCase().includes(texto.toLowerCase())
  );

  return (
    <Footer>
      <div style={{ padding: "7rem", paddingBottom: "3rem" }}>
        <Footer.Widgets>
            <Footer.Title>Centro de ayuda</Footer.Title>
            <Footer.Text>
              Encuentra respuestas a tus preguntas más frecuentes o habla con un
              agente de soporte.
            </Footer.Text>
          {/* Newsletter */}
          <Footer.Newsletter>
            <form action="/.">
                <TextField
                  label=""
                  id="outlined-start-adornment"
                  placeholder="Buscar en el centro de ayuda"
                  fullWidth
                  sx={{ display: "flex", alignItems: "center" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: "25px",
                      maxWidth: "600px",
                    },
                  }}
                  onChange={handleChange}
                />
            </form>
          </Footer.Newsletter>
          {/* Mostrar los textos filtrados */}
        </Footer.Widgets>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "0 2rem",
        }}
      >
        {textosFiltrados.map((texto, index) => (
            <Footer.Link key={index}>
              <Link href="/">{texto}</Link>
            </Footer.Link>
        ))}
      </div>
    </Footer>
  );
}
